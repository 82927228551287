export const routes = {
	home: '/',
	articles: '/informacje',
	article: '/informacje/:slug',
	rankings: '/rankingi',
	ranking: '/rankingi/:slug',
	methodologies: '/metodologia',
	methodology: '/metodologia/:slug',
	prezentacje: '/prezentacje',
	rankingJury: '/kapitula-rankingu',
	qualityMark: '/znak-jakosci',
	delegatury: process.env.REACT_APP_THEME === 'LICEA' ? '/mazowiecki-ranking-liceow' : '/mazowiecki-ranking-technikow',
	delegaturyInfo:
		process.env.REACT_APP_THEME === 'LICEA'
			? '/mazowiecki-ranking-liceow/informacje'
			: '/mazowiecki-ranking-technikow/informacje',
	delegaturyInfos:
		process.env.REACT_APP_THEME === 'LICEA'
			? '/mazowiecki-ranking-liceow/informacje/:slug'
			: '/mazowiecki-ranking-technikow/informacje/:slug',
	gallery: '/galeria',
	errata: '/errata',
	school: '/szkola/:slug',
};
