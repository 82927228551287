import React from 'react';
import { Redirect } from 'react-router-dom';

const AppRedirect = () => (
	<div>
		<Redirect to="/rankingi" />
	</div>
);

export default AppRedirect;
