import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContainerTemplate from 'templates/ContainerTemplate';
import Burger from 'components/atoms/Burger/Burger';
import Link from 'components/atoms/Link/Link';
import Dropdown from '../Dropdown/Dropdown';
import 'assets/js/bootstrap';

const expandValue = {
	sm: 'navbar-expand-sm',
	md: 'navbar-expand-md',
	lg: 'navbar-expand-lg',
	xl: 'navbar-expand-xl',
	xxl: 'navbar-expand-xxl',
	fluid: 'navbar-expand-sm',
};

const StyledWrapper = styled.nav`
	list-style: none;
	background-color: ${({ darkmode, theme }) => (darkmode ? theme.grey500 : theme.grey100)};
	border-bottom: 1px solid ${({ theme }) => theme.grey200};

	.nav-link {
		font-size: 0.85rem;

		@media (max-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
			padding: 0.5rem;

			&.active {
				border-bottom: 3px solid ${({ secondtheme, theme }) => (secondtheme ? theme.secondary : theme.primary)};
				font-size: 1.1em;
				font-weight: ${({ theme }) => theme.bold};
				padding-bottom: 0.25rem;
				margin-bottom: 0.25rem;
			}
		}
		@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
			&.active {
				background-color: ${({ secondtheme, theme }) => (secondtheme ? theme.secondary : theme.primary)};
				color: #fff;

				:hover {
					color: #fff;
				}
			}
		}
	}
`;

const NavAside = styled.div`
	font-size: 0.85rem;
	color: ${({ darkmode }) => (darkmode ? ' #fff' : 'initial')};

	${Link} {
		color: ${({ secondtheme, theme }) => (secondtheme ? theme.primary : theme.secondary)};
	}

	@media (max-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		padding: 0.5rem;
		border-top: 3px solid ${({ secondtheme, theme }) => (secondtheme ? theme.primary : theme.secondary)};
		padding-top: 0.25rem;
		margin-top: 0.25rem;
	}

	@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		${Link} {
			display: block;
		}
	}
`;

const OtherLink = styled.div`
	padding: 0.5rem;
	background-color: ${({ darkmode }) => (darkmode ? '#333' : '#f8f9fa')};
	text-align: center;

	@media (min-width: ${({ expand, theme }) => theme.breakpoints[expand]}) {
		display: none;
	}

	${Link} {
		color: ${({ secondtheme, theme }) => (secondtheme ? theme.primary : theme.secondary)};
	}
`;

const Navbar = ({ id, expand, content, darkmode, navAside, secondtheme }) => (
	<>
		<OtherLink
			expand={expand ?? 'sm'}
			style={{ padding: '0.5rem', backgroundColor: darkmode ? '#333' : '#f8f9fa', textAlign: 'center' }}
		>
			Zobacz również:&nbsp;
			<br />
			<Link
				href={`https://${process.env.REACT_APP_EDITION}.${
					process.env.REACT_APP_THEME === 'LICEA' ? 'technika' : 'licea'
				}.perspektywy.pl`}
				darkmode={darkmode}
				secondtheme={secondtheme}
				featured
				style={{ fontSize: '1.5em' }}
			>
				{`Ranking ${process.env.REACT_APP_THEME === 'LICEA' ? 'Techników' : 'Liceów'} ${
					process.env.REACT_APP_EDITION
				}`}
			</Link>
		</OtherLink>
		<StyledWrapper
			className={`navbar ${expandValue[expand]}`}
			secondtheme={secondtheme}
			darkmode={darkmode}
			expand={expand}
		>
			<ContainerTemplate>
				<Burger target={id} dark={!darkmode} />

				<div className="collapse navbar-collapse" id={id}>
					<ul className="navbar-nav me-auto">
						{content &&
							content.map(({ target, url, name, subItems, className }, index) => {
								if (subItems !== null) {
									return (
										<li className="nav-item" key={index}>
											<Dropdown
												target={`dropdown-${index}`}
												darkmode={darkmode}
												secondtheme={secondtheme}
												items={subItems}
												label={name}
											/>
										</li>
									);
								}
								return (
									<li className="nav-item" key={index}>
										{target === '_blank' ? (
											<Link
												href={url}
												target="_blank"
												rel="noreferrer"
												className={`nav-link${className ? ` ${className}` : ''}`}
												darkmode={darkmode}
												secondtheme={secondtheme}
											>
												{name}
											</Link>
										) : (
											<Link
												as={NavLink}
												to={url}
												className={`nav-link${className ? ` ${className}` : ''}`}
												darkmode={darkmode}
												secondtheme={secondtheme}
											>
												{name}
											</Link>
										)}
									</li>
								);
							})}
					</ul>
					{navAside && (
						<NavAside expand={expand ?? 'sm'} darkmode={darkmode}>
							Zobacz również:&nbsp;
							<Link
								// href={`https://${process.env.REACT_APP_EDITION}.${
								// 	process.env.REACT_APP_THEME === 'LICEA' ? 'technika' : 'licea'
								// }.perspektywy.pl`}
								href={`https://${process.env.REACT_APP_EDITION}.${
									process.env.REACT_APP_THEME === 'LICEA' ? 'technika' : 'licea'
								}.perspektywy.pl`}
								darkmode={darkmode}
								secondtheme={secondtheme}
								featured
								style={{ fontSize: '1.5em' }}
							>
								{`Ranking ${process.env.REACT_APP_THEME === 'LICEA' ? 'Techników' : 'Liceów'} ${
									process.env.REACT_APP_EDITION
								}`}
							</Link>
						</NavAside>
					)}
				</div>
			</ContainerTemplate>
		</StyledWrapper>
	</>
);

Navbar.propTypes = {
	id: PropTypes.string.isRequired,
	expand: PropTypes.string,
	content: PropTypes.array.isRequired,
	navAside: PropTypes.bool,
};

Navbar.defaultProps = {
	expand: 'sm',
	navAside: false,
};

const mapStateToProps = ({ global }) => ({ year: global.year });
export default connect(mapStateToProps)(Navbar);
